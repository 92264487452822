
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ElectricityEmissionClient, EstimationClient, GarmentDefaultDataClient, TransportDistanceCalculationClient, TransportTypeConfigurationClient } from '@/services/Services';
import EditLayerTransportationProductionProcessModal from '../../../modals/editLayerTransportationProductionProcessModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditFinalTransportation extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() garmentConfigurationIdentifier: string;
    @Prop() saveToGarmentConfiguration: boolean;
    
    electricityCountries: OM.TextIdentifier[] = [];
    transportationCountries: OM.TransportDistanceCalculationVM[] = [];
    transportTypes: string[] = [];
    layerCompositions: OM.LayerComposition[] = [];

    finalTransportation: OM.TransportProductionPhase[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }
    
    init() {
        Promise.all([
            store.state.estimationClient.getFinalTransportation(this.estimationIdentifier),
            store.state.estimationClient.getEstimationsGarmentLayers(this.estimationIdentifier, null, true),
            ElectricityEmissionClient.getCountries(true),
            TransportDistanceCalculationClient.getAllConfigurations(),
            TransportTypeConfigurationClient.getAllTransportTypes()
        ])
        .then( xs => {
            this.finalTransportation = xs[0];
            this.layerCompositions = xs[1];
            this.electricityCountries = xs[2];
            this.transportationCountries = xs[3];
            this.transportTypes = xs[4].map(x => x.transportType);

            this.loaded = true;
        })
    }

    get filteredFinalTransportation() {
        if(this.finalTransportation.length > 0) {
            var ris = new OM.TransportProductionPhase();
            ris = this.finalTransportation[0];
            return ris;
        }
        else
            return null;
    }

    editTransportation() {
        var allMaterials = [];
        this.layerCompositions.forEach(layer => {
            layer.fabricCompositions.forEach(element => {
                allMaterials.push(element.rawMaterial);
            });
        });

        var editModel = new OM.TransportProductionPhase();
        if(this.filteredFinalTransportation && this.filteredFinalTransportation.countryOfDestination)
            editModel = this.filteredFinalTransportation;

        this.$opModal.show(EditLayerTransportationProductionProcessModal, {
            estimationIdentifier: this.estimationIdentifier,
            productionPhase: "",
            layer: null,
            allMaterials: allMaterials,
            transportDistanceCalculation: this.transportationCountries,
            countries: this.electricityCountries,
            editModel: editModel,
            saveCallback: (editLayerTransportationVM : OM.EditLayerTransportationVM) => {
                var model = new OM.EditFinalTransportationVM();
                model.fabricComposition = null;
                
                this.layerCompositions.forEach(layer => {
                    layer.fabricCompositions.forEach(element => {
                        model.allFabricComposition.push(element);
                    });
                });
                
                model.countryOfOrigin = editLayerTransportationVM.countryOfOrigin;
                model.countryOfDestination = editLayerTransportationVM.countryOfDestination;
                model.identifier = editLayerTransportationVM.identifier;
                model.layerName = editLayerTransportationVM.layerName;
                model.productionPhase = editLayerTransportationVM.productionPhase;
                model.transportTypes = editLayerTransportationVM.transportTypes;

                if(this.saveToGarmentConfiguration){
                    GarmentDefaultDataClient.editFinalTransportation(model)
                    .then( x => {
                        this.$opModal.closeAll();
                        this.init();
                    })
                } else {
                    EstimationClient.editFinalTransportation(model)
                    .then( x => {
                        this.$opModal.closeAll();
                        this.init();
                    })
                }
            }
        })
    }

    removeTransportation(item: OM.TransportProductionPhase){
        var index = this.finalTransportation.indexOf(item);
        
        var deleteModel = new OM.RemoveLayerTransportationVM();
        deleteModel.identifier = this.estimationIdentifier;
        deleteModel.index = index;
        EstimationClient.removeFinalTransportation(deleteModel)
        .then(x => {
            this.$opModal.closeAll();
            this.init();
        })
    }

    next() {
        if(this.saveToGarmentConfiguration){
            alert("finito, esci");
        } else {
            this.$router.push("/calculator/" + this.estimationIdentifier + "/summary");
        }
    }

}
